<template>
  <div class="c-login-popup u-flex u-flex-direction-column u-font-size-medium u-flex-shrink-0">
    <strong class="u-font-size-h4 u-mb--m">{{ model.heading }}</strong>
    <div class="u-flex u-flex-direction-column u-mr--a">
      <a
        class="c-login-popup__button c-login-popup__facebook-button c-button u-mb--xs"
        :href="replaceReturnUrl(model.facebookSignInUrl)"
        @click="trackLoginClick('facebook')"
      >
        <Icon
          :icon="iconFacebook"
          :size="24"
          fill="white"
          class="u-mr--s"
        />
        {{ model.facebookSignInText }}
      </a>
      <a
        class="c-login-popup__button c-login-apple-button c-button c-button--dark u-mb--xs"
        :href="replaceReturnUrl(model.appleSignInUrl)"
        @click="trackLoginClick('apple')"
      >
        <Icon
          :icon="iconApple"
          :size="24"
          fill="white"
          class="u-mr--s"
        />
        {{ model.appleSignInText }}
      </a>
      <a
        class="c-login-popup__button c-button c-button--light u-mb--xs"
        :href="replaceReturnUrl(model.googleSignInUrl)"
        @click="trackLoginClick('google')"
      >
        <Icon
          :icon="iconGoogle"
          :size="24"
          fill="white"
          class="u-mr--s"
        />
        {{ model.googleSignInText }}
      </a>
      <a
        class="c-login-popup__button c-button c-button--ghost"
        :href="replaceReturnUrl(model.emailSignInUrl)"
        @click="trackLoginClick('login button')"
      >
        <Icon
          :icon="iconEmail"
          :size="24"
          stroke="black"
          class="u-mr--s"
        />
        {{ model.emailSignInText }}
      </a>
    </div>
    <div class="u-flex u-flex-direction-column u-mt--m">
      <a
        class="u-mb--xs"
        :href="replaceReturnUrl(model.signUpUrl)"
        @click="trackLoginAreaLink(model.signUpText)"
      >
        {{ model.signUpText }}
      </a>
      <a
        :href="replaceReturnUrl(model.resetPasswordUrl)"
        @click="trackLoginAreaLink(model.resetPasswordText)"
      >
        {{ model.resetPasswordText }}
      </a>
    </div>
  </div>
</template>

<script>
import iconFacebook from '@ds/svg/icons/stroke/social-media-facebook-1.svg'
import iconEmail from '@ds/svg/icons/stroke/email-action-unread.svg'
import iconApple from '@ds/svg/icons/bold/apple-logo.svg'
import iconGoogle from '@ds/svg/icons/bold/google-logo.svg'

import Icon from '@/CVI/WebCore/components/Icon.vue'

import store from '@/CVI/WebCore/store'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    Icon
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      store,
      iconFacebook,
      iconApple,
      iconGoogle,
      iconEmail
    }
  },
  methods: {
    replaceReturnUrl(url) {
      if (store.state.loginReturnUrl) {
        const encodedReturnUrl = encodeURIComponent(store.state.loginReturnUrl)
        return url.replace(/(.*)redirectUri=[^&]+(.*)/i, `$1redirectUri=${encodedReturnUrl}$2`)
      }

      return url
    },
    trackLoginClick(area) {
      tracker.trackLoginSelect({
        loginAreaSelect: area
      })
    },
    trackLoginAreaLink(link) {
      tracker.trackLoginAreaLinks({
        event: 'menuNavigationLoginLinks',
        loginAreaLink: link
      })
    }
  }
}
</script>
